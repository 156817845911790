import { useMemo } from 'react';
import { useResolvedPath } from 'react-router-dom';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { ITabItem, Tabs } from '../../../components/tabs/Tabs';
import { EmailType, DocumentType } from '../../../generated/graphql';
import { AllGeneralSettingsProvider } from '../all-settings-context';
import { PageHeading } from 'components/PageHeading';

const SettingsPage = () => {
  const generalSettingsRoute = useResolvedPath('general');
  const customerRoute = useResolvedPath('customer');
  const actionsRoute = useResolvedPath('actions');
  const orderSettingsRoute = useResolvedPath('order');
  const invoiceSettingsRoute = useResolvedPath('invoice');
  const emailSettingsRoute = useResolvedPath(`email/${EmailType.Invoice}`);
  const documentSettingsRoute = useResolvedPath(`document/${DocumentType.Tro}`);
  const mailboxSettingsRoute = useResolvedPath('mailbox');
  const usersSettingsRoute = useResolvedPath('users');
  const transporeonSettingsRoute = useResolvedPath('transporeon');

  const tabItems = useMemo<ITabItem[]>(() => {
    return [
      {
        title: 'Algemeen',
        path: generalSettingsRoute.pathname,
      },
      {
        title: 'Klanten',
        path: customerRoute.pathname,
      },
      {
        title: 'Acties',
        path: actionsRoute.pathname,
      },
      {
        title: 'Order',
        path: orderSettingsRoute.pathname,
      },
      {
        title: 'Facturatie',
        path: invoiceSettingsRoute.pathname,
      },
      {
        title: 'Email',
        path: emailSettingsRoute.pathname,
      },
      {
        title: 'Document',
        path: documentSettingsRoute.pathname,
      },
      {
        title: 'Mailbox',
        path: mailboxSettingsRoute.pathname,
      },
      {
        title: 'Gebruikers',
        path: usersSettingsRoute.pathname,
      },
      {
        title: 'Transporeon',
        path: transporeonSettingsRoute.pathname,
      },
    ];
  }, []);

  return (
    <>
      <PageHeader title="Instellingen" />

      <PageHeading leftSide={<Breadcrumb currentItem={'Instellingen'} />} />

      <div className="px-4">
        <AllGeneralSettingsProvider>
          <Tabs items={tabItems} />
        </AllGeneralSettingsProvider>
      </div>
    </>
  );
};

export default SettingsPage;
