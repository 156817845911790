import { useState, useCallback, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useParams, useResolvedPath } from 'react-router-dom';
import { invariant } from '@utils/invariant';
import * as Yup from 'yup';
import { ArrowSquareOut } from '@phosphor-icons/react';

import { Breadcrumb } from '../../../../components/Breadcrumb';
import { Button } from '../../../../components/button/Button';
import { DataField } from '../../../../components/DataField';
import { InputDialog } from '../../../../components/dialog/InputDialog';
import { PageHeader } from '../../../../components/PageHeader';
import { StatusText } from '../../../../components/StatusText';
import { ITabItem, Tabs } from '../../../../components/tabs/Tabs';
import { Tooltip } from '../../../../components/tooltip/Tooltip';
import {
  GetOrderByIdQuery,
  OrderDocumentType,
  OrderStatus,
  useGenerateOrderConfirmationMutation,
  useGetOrderByIdQuery,
  useLinkOrderDocumentMutation,
  useSendOrderConfirmationMutation,
  useSendTransportOrderMutation,
  useSendUrgentCmrReminderMutation,
  useUnlinkOrderDocumentMutation,
  SaleStatus,
  useCancelOrderMutation,
  OrderLineStopType,
  EmailType,
  UserRole,
  useGenerateSustainabilityReportMutation,
} from '../../../../generated/graphql';
import { formatDate, sortDate } from '../../../../utils/date';
import { getDisplayError } from '../../../../utils/get-display-error';
import { formatNumber } from '../../../../utils/number';
import { FileUploaderButton } from '../../../document/components/FileUploaderButton';
import { MultiFileUploaderButton } from '../../../document/components/MultiFileUploaderButton';
import { OrderStatusTag } from '../../components/OrderStatusTag';
import {
  ORDER_ORIGINS,
  PURCHASE_STATUS_COLOR,
  PURCHASE_STATUS_OPTIONS,
  SALE_STATUS_COLOR,
  SALE_STATUS_OPTIONS,
} from '../../constants';
import { canCreateOrderLine } from '../../utils/order-status';
import { calculateLinesTotalExclVat, calculateProfitPercentage } from '../../utils/price';
import { OrderLineSale } from '../orderLineSale/OrderLineSales';
import { OrderLinePurchase } from '../orderPurchase/OrderPurchases';
import { OrderProvider } from './orderContext';
import { OrderStatusDialog } from '../../components/OrderStatusDialog';
import { IncomingOrderEmailButton } from './IncomingOrderEmail';
import { ContactsAndContentDialog } from '../../../../components/dialog/ContactsAndContentDialog';
import { useMinimalGeneralSettings } from '../../../../contexts/minimal-settings-context';
import { RatingDatafield } from '../../components/RatingDatafield';
import { useAuth } from '../../../../contexts/auth-context';
import { LinkButton } from '../../../../components/button/ButtonLink';
import classNames from '../../../../utils/classnames';
import { ContactsDialog } from '../../../../components/dialog/ContactsDialog';
import { PageHeading } from 'components/PageHeading';

export type Order = NonNullable<GetOrderByIdQuery['order']>;

function getLineSales(order: Order): OrderLineSale[] {
  let sales: OrderLineSale[] = [];
  for (const line of order.lines) {
    sales.push(...line.sales);
  }
  return sales;
}

function getLinePurchases(order: Order): OrderLinePurchase[] {
  let purchases: OrderLinePurchase[] = [];
  for (const line of order.lines) {
    purchases.push(...line.purchases);
  }
  return purchases;
}

const OrderPage = () => {
  const { me } = useAuth();
  const { orderId } = useParams<{ orderId: string }>();
  invariant(orderId);
  const { settings: minimalSettings } = useMinimalGeneralSettings();
  const [{ data, error }, reexecuteQuery] = useGetOrderByIdQuery({
    variables: {
      id: orderId,
    },
  });
  const [, linkDocument] = useLinkOrderDocumentMutation();
  const [, unlinkDocument] = useUnlinkOrderDocumentMutation();
  const [_sendOrderConfirmationState, sendOrderConfirmation] = useSendOrderConfirmationMutation();
  const [_sendTransportOrderState, sendTransportOrder] = useSendTransportOrderMutation();
  const [_sendUrgentCmrReminderState, sendUrgentCmrReminder] = useSendUrgentCmrReminderMutation();
  const [_genOrderConfirmationState, generateOrderConfirmation] = useGenerateOrderConfirmationMutation();
  const [_genSustainabilityReportState, generateSustainabilityReport] = useGenerateSustainabilityReportMutation();
  const [_cancelOrderState, cancelOrder] = useCancelOrderMutation();
  const [showManualStatusChangeDialog, setShowManualStatusChangeDialog] = useState(false);

  const order = data?.order;

  const refetchData = useCallback(() => {
    return reexecuteQuery({
      requestPolicy: 'network-only',
    });
  }, [reexecuteQuery]);

  const generalRoute = useResolvedPath('general');
  const linesRoute = useResolvedPath(order?.lines.length === 1 ? `lines/${order.lines[0].id}/contents` : 'lines');
  const complaintsRoute = useResolvedPath('complaints');
  const auditLogsRoute = useResolvedPath('audit-logs');
  const tabItems = useMemo<ITabItem[]>(() => {
    const res = [
      {
        title: 'Algemeen',
        path: generalRoute.pathname,
      },
      {
        title: `Opdrachten (${order?.lines.length ?? '0'})`,
        path: linesRoute.pathname,
      },
      {
        title: `Klachten (${order?.complaints.length ?? '0'})`,
        path: complaintsRoute.pathname,
      },
    ];

    if (me.role === UserRole.Admin) {
      res.push({
        title: 'Audit logs',
        path: auditLogsRoute.pathname,
      });
    }

    return res;
  }, [order, me]);

  const purchaseTotal = useMemo(() => {
    return order ? calculateLinesTotalExclVat(getLinePurchases(order)) : 0;
  }, [order]);
  const saleTotal = useMemo(() => {
    return order ? calculateLinesTotalExclVat(getLineSales(order)) : 0;
  }, [order]);

  const missingOrderData = useMemo(() => {
    if (!order) {
      return 'Opdracht niet gevonden';
    }

    if (!order.lines.length) {
      return 'Geen opdrachten';
    }

    if (!order.customer) {
      return 'Geen klant geselecteerd';
    }

    for (let i = 0; i < order.lines.length; i++) {
      const line = order.lines[i];
      if (!line.contents.length) {
        return `Geen inhoud voor opdracht ${i + 1}`;
      }

      const hasLoad = line.stops.some((s) => s.type === OrderLineStopType.Load);
      const hasUnload = line.stops.some((s) => s.type === OrderLineStopType.Unload);
      if (!hasLoad || !hasUnload) {
        return `Geen laad- of lossen voor opdracht ${i + 1}`;
      }
    }
  }, [order]);

  if (!order) {
    if (error) {
      return <div>Kon order niet laden: {error.message}</div>;
    } else {
      return <div>Order niet gevonden</div>;
    }
  } else {
    // Get lowest loadDate from all stops
    const executionDate = order.lines
      .map((l) => {
        return l.stops.filter((s) => s.type === OrderLineStopType.Load).sort((a, b) => sortDate(a.date, b.date))[0]
          ?.date;
      })
      .sort(sortDate)[0];
    const pageTitle = `Order ${order.orderNumber ?? 'DRAFT'}`;

    const profit = saleTotal - purchaseTotal;
    const profitPercentage = calculateProfitPercentage(purchaseTotal, saleTotal);

    const purchaseStatusColor = PURCHASE_STATUS_COLOR[order.purchaseStatus] ?? 'black';
    const purchaseStatusText =
      PURCHASE_STATUS_OPTIONS.find((i) => i.key === order.purchaseStatus)?.name ?? order.purchaseStatus;
    const saleStatusColor = SALE_STATUS_COLOR[order.saleStatus] ?? 'black';
    const saleStatusText = SALE_STATUS_OPTIONS.find((i) => i.key === order.saleStatus)?.name ?? order.saleStatus;

    const hasCmr = !!order.documents.find((d) => d.type === OrderDocumentType.Cmr)?.document;
    const originText = ORDER_ORIGINS[order.origin];
    const distance = Math.round(order.lines.reduce((acc, curr) => curr.distance + acc, 0) / 1000);
    const emissions = order.lines.reduce((acc, curr) => curr.emissions + acc, 0);
    return (
      <>
        <OrderProvider order={order} refreshData={refetchData}>
          <PageHeader title={pageTitle} />

          {showManualStatusChangeDialog && (
            <OrderStatusDialog
              orderId={order.id}
              initialStatus={order.status}
              isOpen={showManualStatusChangeDialog}
              setOpen={setShowManualStatusChangeDialog}
            />
          )}

          <PageHeading
            leftSide={
              <div className="flex items-center gap-2">
                <Breadcrumb
                  parentItem={{
                    name: 'Orders',
                    to: '/internal/orders',
                  }}
                  currentItem={pageTitle}
                />
                <div
                  className="ml-4 cursor-pointer"
                  onClick={() => {
                    setShowManualStatusChangeDialog(true);
                  }}
                >
                  <OrderStatusTag status={order.status} />
                </div>
              </div>
            }
            rightSide={
              <div className="flex gap-2">
                {![OrderStatus.Executed, OrderStatus.Confirmed, OrderStatus.Cancelled].includes(order.status) &&
                  (!missingOrderData ? (
                    <ContactsAndContentDialog
                      triggerText="Bevestig order"
                      triggerColor={order.status === OrderStatus.Draft ? 'primary' : 'default'}
                      title="Verstuur order bevestiging"
                      submitText="Verstuur"
                      description="Ben je zeker dat de order volledig is?"
                      contacts={
                        order.customer?.contacts
                          .filter((c) => c.shouldReceiveOrderConfirmations)
                          .map((c) => {
                            return {
                              id: '' + c.id,
                              name: c.name,
                              email: c.email,
                            };
                          }) ?? []
                      }
                      initialContent={
                        minimalSettings.emailContents.find(
                          (v) => v.emailType === EmailType.OrderConfirmation && v.language === order.customer?.language,
                        )?.content ?? ''
                      }
                      initialValue={
                        order.customer?.contacts
                          .filter((c) => c.shouldReceiveOrderConfirmations)
                          .map((v) => '' + v.id) ?? []
                      }
                      onSubmit={async (values) => {
                        try {
                          const res = await sendOrderConfirmation({
                            orderId: order.id,
                            contentOverwrite: values.content,
                            contacts: values.contacts.map((c) => +c),
                          });
                          if (res.error) {
                            throw res.error;
                          }
                          toast.success('Order bevestiging verstuurd');
                        } catch (err: any) {
                          toast.error('Kon order bevestiging niet versturen: ' + getDisplayError(err));
                          throw err;
                        }
                      }}
                    />
                  ) : (
                    <Tooltip content={`Kan order niet bevestigen, ${missingOrderData}`}>
                      <Button isDisabled={true}>Bevestig Order</Button>
                    </Tooltip>
                  ))}

                {[OrderStatus.Created, OrderStatus.Placed, OrderStatus.Confirmed].includes(order.status) &&
                  order.customer &&
                  order.supplier && (
                    <ContactsAndContentDialog
                      triggerText="Verzend Transport Opdracht"
                      triggerColor={order.status === OrderStatus.Created ? 'primary' : 'default'}
                      title="Verzend transport opdracht"
                      submitText="Verzend"
                      description={`Ben je zeker dat je de transport opdracht naar ${order.supplier?.name} wilt verzenden?`}
                      contacts={order
                        .supplier!.contacts.filter((c) => c.shouldReceiveTransportOrders)
                        .map((c) => {
                          return {
                            id: '' + c.id,
                            name: c.name,
                            email: c.email,
                          };
                        })}
                      initialContent={
                        minimalSettings.emailContents.find(
                          (v) => v.emailType === EmailType.TransportOrder && v.language === order.supplier?.language,
                        )?.content ?? ''
                      }
                      initialValue={order
                        .supplier!.contacts.filter((c) => c.shouldReceiveTransportOrders)
                        .map((v) => '' + v.id)}
                      onSubmit={async (value) => {
                        try {
                          const res = await sendTransportOrder({
                            orderId: order.id,
                            contentOverwrite: value.content,
                            contacts: value.contacts.map((c) => +c),
                          });
                          if (res.error) {
                            throw res.error;
                          }
                          toast.success('Transport order verstuurd');
                        } catch (err: any) {
                          toast.error('Kon transport order niet versturen: ' + getDisplayError(err));
                          throw err;
                        }
                      }}
                    />
                  )}

                {[OrderStatus.Executed].includes(order.status) && order.customer && order.supplier && (
                  <ContactsDialog
                    triggerText="Verzend CMR Herinnering"
                    triggerColor={hasCmr ? 'default' : 'primary'}
                    title="Verzend CMR Herinnering"
                    submitText="Verzend"
                    description={`Ben je zeker dat je de transporteur ${order.supplier?.name} een CMR herinnering wilt sturen?`}
                    contacts={order
                      .supplier!.contacts.filter((c) => c.shouldReceiveTransportOrders)
                      .map((c) => {
                        return {
                          id: '' + c.id,
                          name: c.name,
                          email: c.email,
                        };
                      })}
                    initialValue={order
                      .supplier!.contacts.filter((c) => c.shouldReceiveTransportOrders)
                      .map((v) => '' + v.id)}
                    onSubmit={async (value) => {
                      try {
                        const res = await sendUrgentCmrReminder({
                          orderId: order.id,
                          contacts: value.map((c) => +c),
                        });
                        if (res.error) {
                          throw res.error;
                        }
                        toast.success('CMR Herinnering verstuurd');
                      } catch (err: any) {
                        toast.error('Kon cmr herinnering niet versturen: ' + getDisplayError(err));
                        throw err;
                      }
                    }}
                  />
                )}

                <LinkButton to={`/internal/orders/new?copy-from=${order.id}`}>Dupliceer</LinkButton>

                {order.status !== OrderStatus.Cancelled && order.saleStatus !== SaleStatus.Invoiced && (
                  <InputDialog
                    triggerText="Annuleer order"
                    triggerColor="danger"
                    title={`Annuleer order ${order.orderNumber ?? '-'}`}
                    submitText="Annuleer"
                    description={`Ben je zeker dat je order ${order.orderNumber ?? '-'} wilt annuleren?`}
                    labelText="Reden"
                    validation={Yup.string().min(5, 'Minimum 5 karakters')}
                    onSubmit={async (value: string) => {
                      try {
                        const res = await cancelOrder({
                          orderId: order.id,
                          reason: value,
                        });
                        if (res.error) {
                          throw res.error;
                        }
                        toast.success('Order geannuleerd');
                      } catch (err: any) {
                        toast.error('Kon order niet annuleren: ' + getDisplayError(err));
                        throw err;
                      }
                    }}
                  />
                )}
              </div>
            }
          />

          <div className="px-4">
            <div className="flex justify-between gap-8 mb-4">
              <div className="flex-1 flex flex-wrap gap-6">
                <DataField title="Origine">
                  {order.originUrl ? (
                    <Link to={order.originUrl} className="text-feedback-informative underline flex gap-1 items-center">
                      {originText} <ArrowSquareOut className="w-4 h-4" />
                    </Link>
                  ) : (
                    originText
                  )}
                </DataField>
                {order.customer && (
                  <DataField title="Klant">
                    <span
                      className={classNames({
                        'text-feedback-negative-04': !!order.customer.deactivationReason,
                      })}
                    >{`${order.customer.name} - #${order.customer.id}${
                      order.customer.deactivationReason ? ' (gedeactiveerd)' : ''
                    }`}</span>
                  </DataField>
                )}
                {order.supplier && (
                  <DataField title="Vervoerder">
                    <span
                      className={classNames({
                        'text-feedback-negative-04': !!order.supplier.deactivationReason,
                      })}
                    >
                      {`${order.supplier.name} - #${order.supplier.id}${
                        order.supplier.deactivationReason ? ' (gedeactiveerd)' : ''
                      }`}
                    </span>
                  </DataField>
                )}
                {order.supplierTruck && <DataField title="Trekker">{order.supplierTruck.licensePlate}</DataField>}
                <DataField title="Referentie klant">{order.customerRef}</DataField>
                <DataField title="Ingave datum">{formatDate(order.createdAt)}</DataField>
                {distance > 0 && <DataField title="Afstand">{`${distance}km`}</DataField>}
                {emissions > 0 && (
                  <DataField title="Emissies">{`${formatNumber(emissions, 3, {
                    decimalSeperator: ',',
                  })}kg co2eq`}</DataField>
                )}
                {executionDate && <DataField title="Uitvoering datum">{formatDate(executionDate)}</DataField>}
                {order.cmrSentAt && <DataField title="CMR Verstuurd op">{formatDate(order.cmrSentAt)}</DataField>}
                {order.creator && <DataField title="Verantwoordelijke">{order.creator.name}</DataField>}
                {order.supplier && !order.supplier.regularCarrier && <RatingDatafield order={order} />}
                {order.cancellationReason && <DataField title="Annuleringsreden">{order.cancellationReason}</DataField>}
              </div>

              <div
                className="grid gap-x-4 gap-y-1 ml-4 self-start card p-2"
                style={{ gridTemplateColumns: 'repeat(5, 1fr)', width: '24rem' }}
              >
                <div className="text-sm">Verkoop</div>
                <div className="text-right whitespace-nowrap col-span-2">{`${
                  saleTotal > 0
                    ? formatNumber(saleTotal, 2, {
                        decimalSeperator: ',',
                      })
                    : '-'
                } €`}</div>
                <div className="ml-auto col-span-2">
                  <Link
                    to={order.purchase ? `/internal/finance/sales/${order.purchase.id}` : '/internal/finance/sales/new'}
                  >
                    <StatusText color={saleStatusColor}>{saleStatusText}</StatusText>
                  </Link>
                </div>
                <div className="text-sm">Aankoop</div>
                <div className="text-right whitespace-nowrap col-span-2">{`${
                  purchaseTotal > 0
                    ? formatNumber(purchaseTotal, 2, {
                        decimalSeperator: ',',
                      })
                    : '-'
                } €`}</div>
                <div className="ml-auto col-span-2">
                  <Link
                    to={
                      order.purchase
                        ? `/internal/finance/purchases/${order.purchase.id}`
                        : '/internal/finance/purchases/new'
                    }
                  >
                    <StatusText color={purchaseStatusColor}>{purchaseStatusText}</StatusText>
                  </Link>
                </div>
                <div className="bg-gray-400 my-2 col-span-5" style={{ height: 1 }}></div>
                <div className="text-sm">Winst</div>
                <div className="text-right whitespace-nowrap col-span-2">{`${
                  profit
                    ? formatNumber(profit, 2, {
                        decimalSeperator: ',',
                      })
                    : '-'
                } €`}</div>
                <div className="text-right whitespace-nowrap col-span-2">
                  {`${
                    profitPercentage > 0
                      ? formatNumber(profitPercentage, 2, {
                          decimalSeperator: ',',
                        })
                      : '-'
                  }%`}
                </div>
              </div>
            </div>

            <div className="flex flex-wrap justify-end gap-2 my-4">
              {!!order.incomingOrderEmail.length && <IncomingOrderEmailButton email={order.incomingOrderEmail[0]} />}

              <FileUploaderButton
                buttonText="ODB"
                title="Order bevestiging"
                file={order.documents.find((d) => d.type === OrderDocumentType.OrderConfirmation)?.document}
                generateDocument={async () => {
                  const res = await generateOrderConfirmation({
                    orderId: order.id,
                  });
                  if (res.error) {
                    throw res.error;
                  }
                }}
                disableUpload
              />

              <FileUploaderButton
                buttonText="SR"
                title="Sustainability Report"
                file={order.documents.find((d) => d.type === OrderDocumentType.SustainabilityReport)?.document}
                generateDocument={async () => {
                  const res = await generateSustainabilityReport({
                    orderId: order.id,
                  });
                  if (res.error) {
                    throw res.error;
                  }
                }}
                disableUpload
              />

              <FileUploaderButton
                buttonText="TRO"
                title="Transport opdracht"
                file={order.documents.find((d) => d.type === OrderDocumentType.TransportOrder)?.document}
                disableUpload
              />

              <FileUploaderButton
                buttonText="CMR"
                title="CMR"
                initialName={`CMR ${order.orderNumber ?? ''}`}
                file={order.documents.find((d) => d.type === OrderDocumentType.Cmr)?.document}
                onSubmit={async (document) => {
                  const result = await linkDocument({
                    orderId: order.id,
                    documentId: document.id,
                    type: OrderDocumentType.Cmr,
                  });
                  if (result.error) {
                    throw result.error;
                  }
                }}
                unlinkDocument={async () => {
                  const document = order.documents.find((d) => d.type === OrderDocumentType.Cmr)?.document;
                  if (!document) {
                    return;
                  }

                  try {
                    const result = await unlinkDocument({
                      orderId: order.id,
                      documentId: document.id,
                      type: OrderDocumentType.Cmr,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                    toast.success('Bestand gewist');
                  } catch (err) {
                    toast.error(`Kon bestand niet wissen: ${getDisplayError(err)}`);
                  }
                }}
                disableUpload={order.status === OrderStatus.Cancelled}
              />

              <FileUploaderButton
                buttonText="AKF"
                title="Aankoopfactuur"
                initialName="Aankoopfactuur"
                file={order.purchase?.document}
                disableUpload
              />

              <MultiFileUploaderButton
                buttonText="Andere"
                title="Andere"
                files={order.documents.filter((d) => d.type === OrderDocumentType.Other).map((f) => f.document)}
                onSubmit={async (documents) => {
                  let count = 0;
                  await Promise.allSettled(
                    documents.map(async (doc) => {
                      try {
                        const result = await linkDocument({
                          orderId: order.id,
                          documentId: doc.id,
                          type: OrderDocumentType.Other,
                        });
                        if (result.error) {
                          throw result.error;
                        }
                        count += 1;
                        toast.success(`Bestand: ${doc.name}, ${count}/${documents.length} opgeladen`);
                      } catch (err) {
                        toast.error(`Kon bestand ${doc.name} niet opladen: ${getDisplayError(err)}`);
                      }
                    }),
                  );
                }}
                unlinkDocument={async (docId: string) => {
                  try {
                    const result = await unlinkDocument({
                      orderId: order.id,
                      documentId: docId,
                      type: OrderDocumentType.Other,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                    toast.success('Bestand gewist');
                  } catch (err) {
                    toast.error(`Kon bestand niet wissen: ${getDisplayError(err)}`);
                  }
                }}
                disableUpload={order.status === OrderStatus.Cancelled}
              />
            </div>

            <Tabs
              items={tabItems}
              actions={canCreateOrderLine(order.status) && <LinkButton to="lines/new">Nieuwe Opdracht</LinkButton>}
            />
          </div>
        </OrderProvider>
      </>
    );
  }
};

export default OrderPage;
