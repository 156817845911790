import type { ISimpleSelectItem } from '../components/select/SimpleSelect';
import { Country } from '../generated/graphql';

export const COUNTRY_VALUES: ISimpleSelectItem[] = Object.values(Country).map((v) => ({
  key: v,
  name: v,
}));

export function parseAddressString(_address: string): { street: string; streetNumber: string } {
  // Clean up the input string
  const address = _address.replace(/\s+/g, ' ').trim();

  // Match common patterns:
  // 1. Street name followed by number and optional extras (e.g., "Lilla Bommen 1, 16 tr")
  // 2. Street name with number (e.g., "Industrieweg 147")
  const patterns = [
    /^(.*?)\s+(\d+(?:[-\/]?\w*)?(?:,?\s+\d+\s+\w+)?)\s*$/, // Matches complex numbers with optional floor/apartment
    /^(.*?)\s+(\d+\w*)\s*$/, // Simple street number pattern
  ];

  for (const pattern of patterns) {
    const match = address.match(pattern);
    if (match) {
      return {
        street: match[1].trim(),
        streetNumber: match[2].trim(),
      };
    }
  }

  // If no patterns match, assume it's just a street name
  return {
    street: address.trim(),
    streetNumber: '',
  };
}
