import * as Dialog from '@radix-ui/react-dialog';
import React, { useState } from 'react';
import { AnySchema } from 'yup';

import { BaseButton, BaseButtonProps } from '../button/BaseButton';
import { FormikProvider, useFormik } from 'formik';

export interface IFormDialogProps<V> {
  triggerText: React.ReactNode;
  triggerTitle?: string;
  triggerColor?: BaseButtonProps['color'];
  title: string;
  validationSchema?: AnySchema;
  cancelText?: string;
  submitText: string;
  onSubmit: (values: V) => void | Promise<void>;
  initialValues: V;
  children: React.ReactNode;
  isDisabled?: boolean;
}

export function FormDialog<V>(props: IFormDialogProps<V>) {
  const {
    triggerText,
    triggerColor,
    title,
    cancelText = 'Annuleer',
    submitText,
    children,
    initialValues,
    validationSchema,
    onSubmit,
    isDisabled,
    triggerTitle,
  } = props;
  const [open, setOpen] = useState(false);

  const formikbag = useFormik({
    initialValues: initialValues as any,
    validationSchema,
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
        setOpen(false);
      } catch (err) {
        // do nothing...
      }
    },
  });

  const { handleSubmit, isSubmitting } = formikbag;
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild disabled={formikbag.isSubmitting}>
        <BaseButton isLoading={formikbag.isSubmitting} color={triggerColor} disabled={isDisabled} title={triggerTitle}>
          {triggerText}
        </BaseButton>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay className="dialog-overlay" />
        <Dialog.Content className="dialog-content">
          <Dialog.Title className="heading-one mb-4">{title}</Dialog.Title>

          <FormikProvider value={formikbag}>
            <form onSubmit={handleSubmit}>
              {children}

              <div className="flex justify-between">
                <div>
                  <BaseButton
                    type="button"
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();

                      setOpen(false);
                    }}
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {cancelText}
                  </BaseButton>
                </div>
                <div>
                  <BaseButton
                    type="submit"
                    color="primary"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();

                      handleSubmit();
                    }}
                  >
                    {submitText}
                  </BaseButton>
                </div>
              </div>
            </form>
          </FormikProvider>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
